import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TaskManagementService } from '../../../services/task-management.service';
import * as moment from 'moment/moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RosterServiceService} from '../../../services/roster-service.service';
import {SystemConfigurationService} from '../../../services/system-configuration.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.css']
})
export class TasksListComponent implements OnInit {
  searchTaskForm: FormGroup;

  page = 1;
  totalPages: any;
  perPage = 25;
  perPageValue: any;
  list: any;
  openAddTaskModal = false;
  isMainModalOpen = true;
  taskId: any;
  fromDate: any;
  toDate: any;
  taskStatus: any;
  priority: any;
  locationData: any;
  project: any;
  depart: any;
  screens = [];
  tagListData: any;
  employee: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private task: TaskManagementService,
    private fb: FormBuilder,
    private rosterServiceService: RosterServiceService,
    private systemConfigService: SystemConfigurationService,
  ) {
  }

  ngOnInit(): void {
    this.searchTaskForm = this.fb.group({
      title: [''],
      status: [''],
      department: [''],
      location: [''],
      priority: [''],
      type: [''],
      project: [''],
      related_to: [''],
      screen: [''],
      tags: [''],
      assign_to: [''],
    });
    this.perPageValue = 25;
    this.searchFilter();
    this.getStatus();
    this.getPriority();
    this.getProject();
    this.getDepartments();
    this.fetchScreens();
    this.getAllTags();
    this.getEmployess();
    setTimeout((e) => {
      $('#type').selectpicker('refresh');
      $('#type').selectpicker();
      $('#related_to').selectpicker('refresh');
      $('#related_to').selectpicker();
    }, 500);
  }
  searchFilter(): any {

    this.ngxLoader.start();
    this.searchTaskForm.get('tags').setValue($('#selectTags').val().toString());
    this.task
      .getTaskListing(
        this.page,
        this.perPage,
        this.searchTaskForm.value
      )
      .subscribe(
        (data) => {
          this.list = data;
          this.totalPages = this.list.total_count;
          this.list = this.list.items;
          $('#search-task').removeClass('offcanvas-on');
          this.resetSelectedData();
          this.closeAddTaskModal();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
  selectPaginationLimit(event): any {
    this.page = 1;
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchFilter();
  }

  loadPage(event): any {
    this.searchFilter();
  }

  openSearchList(): any {
    $('#search-task').toggleClass('offcanvas-on');
    $('.select2').select2({
      placeholder: 'Select or type Tags',
      tags: true,
    });
    setTimeout(() => {
      $('#selectTags').trigger('change');
    }, 1000);
  }
  closeSeacrhList(): any {
    $('#search-task').removeClass('offcanvas-on');
    this.searchTaskForm.patchValue({
      title: '',
      status: '',
      type: '',
      priority: '',
      location: '',
      project: '',
      related_to: '',
      department: '',
      screen: '',
      assign_to: '',
    });
    setTimeout((e) => {
      $('#selectStatus').selectpicker('refresh');
      $('#selectStatus').selectpicker();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
      $('#priority').selectpicker('refresh');
      $('#priority').selectpicker();
      $('#screen_id').selectpicker('refresh');
      $('#type').selectpicker('refresh');
      $('#type').selectpicker();
      $('#related_to').selectpicker('refresh');
      $('#related_to').selectpicker();
      $('#assign_to').selectpicker('refresh');
      $('#assign_to').selectpicker();
    }, 500);
    $('#selectTags').val('');
  }
  openModal(id): any {
    this.taskId = id;
    this.openAddTaskModal = true;
    $('#taskFile').val('');
    if (!this.taskId) {
      $('#medicationsAllergies').val(null).trigger('change');
    }
    $('#viewTaskModal').modal('show');
  }

  closeAddTaskModal(): any{
    this.openAddTaskModal = false;
    $('#viewTaskModal').modal('hide');
  }

  addTask(event: any): any{
    if (event === 'true') {
      this.callMainFunction();
      $('#viewTaskModal').modal('hide');
    } else {
      this.callMainFunction();
    }
  }

  callMainFunction(): any{
    setTimeout((e) => {
      this.searchFilter();
    }, 500);
  }

  onSubmit(): any{
    this.searchFilter();
  }

  getStatus(): any {
    this.task.getStatusList().subscribe(
      (data) => {
        this.taskStatus = data;
        const defaultStatus = this.taskStatus.filter(
          (t) => t.is_default === true,
        );
        // this.addComplaint.get('status').patchValue(defaultStatus[0].id);
        setTimeout((e) => {
          $('#selectStatus').selectpicker('refresh');
          $('#selectStatus').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getPriority(): any {
    this.priority = [];
    this.task.getPriority().subscribe(
      (data) => {
        this.priority = data;
        // this.addComplaint.get('priority').patchValue(this.priority[0].id);
        setTimeout((e) => {
          $('#priority').selectpicker('refresh');
          $('#priority').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getProject(): any {
    this.task.getProject().subscribe(
      (data) => {
        this.project = data;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchScreens(): any {
    this.ngxLoader.start();
    this.screens = [];
    this.systemConfigService.getScreensV2('true').subscribe(
      (data) => {
        this.screens = data.screens;
        setTimeout(() => {
          $('#screen_id').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }

  getAllTags(): any {
    this.tagListData = [];
    this.task.getTagList().subscribe(
      (data) => {
        this.tagListData = data;
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getEmployess(): any {
    this.task.getEmployees('').subscribe(
      (data) => {
        this.employee = data;
        setTimeout((e) => {
          $('#assign_to').selectpicker('refresh');
          $('#assign_to').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  resetSelectedData(): any{
    this.searchTaskForm.patchValue({
      title: '',
      status: '',
      type: '',
      priority: '',
      location: '',
      project: '',
      related_to: '',
      department: '',
      screen: '',
      assign_to: '',
    });
    setTimeout((e) => {
      $('#selectStatus').selectpicker('refresh');
      $('#selectStatus').selectpicker();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
      $('#priority').selectpicker('refresh');
      $('#priority').selectpicker();
      $('#screen_id').selectpicker('refresh');
      $('#type').selectpicker('refresh');
      $('#type').selectpicker();
      $('#related_to').selectpicker('refresh');
      $('#related_to').selectpicker();
      $('#assign_to').selectpicker('refresh');
      $('#assign_to').selectpicker();
    }, 200);
    $('#selectTags').val('');
    $('.select2').select2({
      placeholder: 'Select or type Tags',
      tags: true,
    });
    setTimeout(() => {
      $('#selectTags').trigger('change');
    }, 1000);
  }
}
