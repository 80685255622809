import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { formatDate, TitleCasePipe } from '@angular/common';
import { PatientService } from '../../../services/patient.service';
import { LocationService } from '../../../services/location.service';
import { LookupService } from '../../../services/lookup.service';
import { PatientTypeService } from '../../../services/patient-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteShareService } from '../../../services/route-share.service';
import { Observable, Subscriber, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { UserService } from '../../../services/user.service';
import { LanguageService } from '../../../services/language.service';
import { SystemControlsService } from '../../../services/system-controls.service';
import * as moment from 'moment/moment';
import {customValidations} from "../../../custom-validators/custom-validators";

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-registration-v3',
  templateUrl: './registration-v3.component.html',
  styleUrls: ['./registration-v3.component.css']
})
export class RegistrationV3Component implements OnInit, AfterViewInit {
  @ViewChild('newPatientModal') newPatientModal: ElementRef;
  @ViewChild('conformPatientAdmissionModal')
  conformPatientAdmissionModal: ElementRef;
  @ViewChild('clearConfirmModal') clearConfirmModal: ElementRef;
  @ViewChild('imageModal') imageModal: ElementRef;
  dataValues: any;
  PatientRegistrationForm: FormGroup;
  PatientSearchForm: FormGroup;
  submitted = false;
  patient: any;
  patients: any;
  patient_types = [];
  lookups = [];
  selected_age_type = 'years';
  current_environment: string;
  current_environment_python: string;
  private_patient_type_id: any;
  companies: any;
  groups: any;
  privete_company_id: any;
  private_group_id: any;
  disabled = false;
  age_types = ['years', 'months', 'days', 'hours'];
  current_url = '';
  message = '';

  // for Web Cam
  showImage = false;
  patientImage = '';
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  screenName = '';
  currentPatientInd: any;
  currentControls: any;
  screenControls = [];
  isShowUpDateButton = false;
  isShowFilterName = false;
  isDisable = false;
  patientAgainstPhoneNo = [];
  showAdjustmentButton = true;
  cityList: any;
  stateList: any;
  billingTab = false;
  zakatEligibility = false;
  familyTab = false;
  documentsTab = false;
  appointmentsTab = false;
  isPatientDeceased = false;
  templateData: any;
  defaultLocations: any;
  countriesList: any;
  pattern: any;
  dob: any;
  cnicMandatory = false;

  constructor(
    public titleCasePipe: TitleCasePipe,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private locationService: LocationService,
    private chRef: ChangeDetectorRef,
    private lookupService: LookupService,
    private patientTypeService: PatientTypeService,
    private modalService: NgbModal,
    private routeSevice: RouteShareService,
    private userService: UserService,
    private language: LanguageService,
    private systemControlService: SystemControlsService,
  ) {
    this.dataValues = this.language.getData();
    this.screenName = this.routeSevice.getValue();
  }

  ngAfterViewInit(): any {
    $('#nic').mask('99999-9999999-9');
    $('#guardian_nic').mask('99999-9999999-9');
  }
  ngOnInit(): any {
    const resolvedData = this.route.snapshot.data.resolvedData;
    this.defaultLocations =
      resolvedData.system_general_control.default_location;
    this.current_url = this.router.url;
    this.ngxLoader.stop();
    this.PatientRegistrationForm = this.formBuilder.group({
      title: ['mr', Validators.required],
      first_name: ['', [Validators.required, customValidations()]],
      last_name: [''],
      gender: ['male', Validators.required],
      patient_nic: ['', Validators.compose([Validators.minLength(13)])],
      address1: [''],
      phone1: ['', Validators.required],
      is_zakat_eligible: [''],
      country_id: [''],
      passport_country_id: [''],
      state_id: [''],
      location_id: [''],
      birth_day: [''],
      birth_month: [''],
      birth_year: [''],
      phone1_type: ['mobile'],
      patient_type_id: ['', Validators.required],
      company_id: [''],
      group_id: [''],
      pat_age_type: ['years', Validators.required],
      pat_age_value: [
        '',
        [Validators.required, Validators.min(0), Validators.max(150)],
      ],
      age: [''],
      guardian_nic: ['', Validators.compose([Validators.minLength(13)])],
      guardian_relationship: [''],
      guardian_first_name: [''],
      guardian_phone: [''],
      city_id: [''],
    });

    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    this.getScreenControls();
    this.getPatientTypeList();
    this.getFrontDeskControls();
    this.current_environment = environment.rorUrl;
    this.current_environment_python = environment.pythonUrl;
    this.fetchPatients();
    this.getCity();
    this.getState();
    this.getLookups();
    this.getCountries();
    const queryPatient = this.route.snapshot.queryParamMap.get('patient_mrn');
    if (queryPatient) {
      this.PatientSearchForm.value.type = 'mrn';
      this.PatientSearchForm.value.search_query = queryPatient;
      let referal = false;
      if (this.current_url === '/front-desk') {
        referal = true;
      }
      this.patientService
        .searchPatientRegistrationV2(this.PatientSearchForm.value, referal)
        .subscribe(
          (data) => {
            this.patient = data.patients[0];
            this.fetchPatientInfo(this.patient, 0, false);
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
          },
        );
    }
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      if (
        this.currentControls.patient_registration.enable_cnic_mandatory ===
        true
      ) {
        this.cnicMandatory = true;
      }
      if (this.cnicMandatory) {
        this.PatientRegistrationForm.get('patient_nic').setValidators([
          Validators.required,
          Validators.minLength(13),
        ]);
        this.PatientRegistrationForm.get('patient_nic').updateValueAndValidity();
      }
    });
    setTimeout(() => {
      this.chRef.detectChanges();
    }, 100);
  }
  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        this.updateClinicCheck();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  updateClinicCheck(): any {
    if (this.screenControls.length > 0) {
      if (
        this.screenControls.filter((m) => m.code === 'showupdatebutton')
          .length > 0
      ) {
        this.isShowUpDateButton = this.screenControls.filter(
          (m) => m.code === 'showupdatebutton',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'shownamefilter').length >
        0
      ) {
        this.isShowFilterName = this.screenControls.filter(
          (m) => m.code === 'shownamefilter',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'showadjustmentbutton')
          .length > 0
      ) {
        this.showAdjustmentButton = this.screenControls.filter(
          (m) => m.code === 'showadjustmentbutton',
        )[0].is_show;
      }
    }
  }
  getCountries(): any {
    this.ngxLoader.start();
    this.locationService.getCountries().subscribe(
      (data) => {
        this.countriesList = data.countries;
        this.ngxLoader.stop();
        let country;
        if (this.defaultLocations.country_id) {
          country = this.countriesList.find(
            (t) => t.id === Number(this.defaultLocations.country_id),
          );
        } else if (this.countriesList.find((t) => t.is_default === true)) {
          country = this.countriesList.find((t) => t.is_default === true);
        } else {
          country = this.countriesList[0];
        }
        this.PatientRegistrationForm.patchValue({
          passport_country_id: country?.id,
        });
        this.PatientRegistrationForm.patchValue({ country_id: country?.id });
        this.getPatternAndCountryCode(country?.id);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getPatternAndCountryCode(value): any {
    if (value) {
      const country = this.countriesList.find((t) => t.id === Number(value));
      this.pattern = country.pattern;
      if (!this.pattern) {
        const maxLength = this.f.phone1_type.value === 'mobile' ? 11 : 25;
        const minLength = this.f.phone1_type.value === 'mobile' ? 11 : 1;
        this.PatientRegistrationForm.get('phone1').setValidators([
          Validators.required,
          Validators.minLength(minLength),
          Validators.maxLength(maxLength),
        ]);
        this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
        $('#phone1').unmask(this.pattern);
        this.PatientRegistrationForm.get('phone1').setValue($('#phone1').val());
      } else {
        this.PatientRegistrationForm.get('phone1').clearValidators();
        this.PatientRegistrationForm.get('phone1').updateValueAndValidity();
        $('#phone1').mask(this.pattern);
        this.PatientRegistrationForm.get('phone1').setValue($('#phone1').val());
      }
    }
  }

  getState(): any {
    this.ngxLoader.start();
    this.locationService.getCountryState(this.defaultLocations.country_id).subscribe(
      (data) => {
        this.stateList = data.states;
        let state = '';
          if (this.defaultLocations.state_id) {
            state = this.stateList.find(
              (t) => t.id === Number(this.defaultLocations.state_id),
            ).id;
          } else if (this.stateList.find((t) => t.is_default === true)) {
            state = this.stateList.find((t) => t.is_default === true).id;
          } else {
            state = this.stateList[0].id;
          }
        this.PatientRegistrationForm.patchValue({ state_id: state });
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  getCity(): any {
    this.cityList = [];
    this.locationService.getStateCity(this.defaultLocations.state_id).subscribe(
      (data) => {
        this.cityList = data.cities;
        // this.ngxLoader.stop();
        setTimeout((e) => {
          $('.city').selectpicker('refresh');
          $('.city').selectpicker();
        }, 500);
        let city = '';
        if (this.defaultLocations.city_id) {
          city = this.cityList.find(
            (t) => t.id === Number(this.defaultLocations.city_id),
          ).id;
        } else if (this.cityList.find((t) => t.is_default === true)) {
          city = this.cityList.find((t) => t.is_default === true).id;
        } else {
          city = this.cityList[0].id;
        }
        this.PatientRegistrationForm.patchValue({ city_id: city });
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getPatientTypeList(): any {
    this.patientTypeService.getSelfPatientTypeV2().subscribe((data) => {
      this.patient_types = data.patient_types;
      if (this.patient) {
        this.setPatientType();
      } else {
        const response = this.patient_types.filter(
          (t) => t.is_default === true,
        )[0];
        this.private_patient_type_id = response.id;

        this.companies = response.companies;
        let companiesResponse;
        if (this.companies.filter((t) => t.is_default === true)[0]) {
          companiesResponse = this.companies.filter(
            (t) => t.is_default === true,
          )[0];
        } else {
          companiesResponse = this.companies[0];
        }

        this.privete_company_id = companiesResponse.id;
        this.groups = companiesResponse.groups;
        if (this.groups.filter((t) => t.is_default === true)[0]) {
          this.private_group_id = this.groups.filter(
            (t) => t.is_default === true,
          )[0].id;
        } else if (this.groups.length > 0) {
          this.private_group_id = this.groups[0].id;
        }
        this.PatientRegistrationForm.patchValue({
          patient_type_id: this.private_patient_type_id,
          company_id: this.privete_company_id,
          group_id: this.private_group_id,
        });
      }
    });
  }

  discountPage(patient, page): any {
    const url_string = decodeURIComponent(
      '/' + page + '?patient_id=' + patient.mrn,
    );
    this.router.navigateByUrl(url_string);
  }
  opdPage(): any {
    this.router.navigate(['opd']);
  }
  admissionPage(patient, page): any {
    if (patient.admitted === false) {
      this.ngxLoader.start();
      const url_string = decodeURIComponent(
        '/' + page + '?patient_id=' + patient.mrn,
      );
      this.router.navigateByUrl(url_string);
    } else {
      this.modalService.open(this.conformPatientAdmissionModal);
    }
  }
  admissionPageConform(patient, page): any {
    this.modalService.dismissAll(this.conformPatientAdmissionModal);
    // $('#conformPatientAdmission').modal('hide');
    this.ngxLoader.start();
    const url_string = decodeURIComponent(
      '/' + page + '?patient_id=' + patient.mrn,
    );
    this.router.navigateByUrl(url_string);
  }

  print(url: string): any {
    window.open(
      this.current_environment +
      '/v3/patients/' +
      this.patient.id +
      '/' +
      url +
      '.pdf?auth_token=' +
      localStorage.getItem('auth_token') +
      '&medical_unit_id=' +
      localStorage.getItem('current_medical_unit_id') +
      '&is_national=true',
      '_blank',
    );
  }

  printCard(): any {
    if (this.templateData === 'template_1') {
      window.open(
        this.current_environment +
        '/v3/patients/' +
        this.patient.id +
        '/print_health_card.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&is_national=true',
        '_blank',
      );
    } else {
      window.open('/patient/health-card?id=' + this.patient.mrn);
    }
  }
  getFrontDeskControls(): any {
    this.systemControlService.getFrontDeskControlTemplateData().subscribe(
      (data) => {
        this.templateData =
          data.system_front_desk_control.patient_card_template.patient_card_template;
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  getLookups(): any {
    this.lookupService.indexV2('relationship').subscribe((data) => {
      this.lookups = data.lookups;
      this.PatientRegistrationForm.controls.guardian_relationship.setValue('');
    });
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  clearFormConfirm(): any {
    this.modalService.open(this.clearConfirmModal);
  }

  resetPatientRegistrationForm(): any {
    this.patientImage = '';

    this.modalService.dismissAll(this.clearConfirmModal);

    this.PatientRegistrationForm.reset({
      title: 'mr',
      first_name: '',
      last_name: '',
      gender: 'male',
      patient_nic: '',
      address1: '',
      phone1: '',
      pat_age_type: 'years',
      pat_age_value: '',
      blood_group: '',
      guardian_nic: '',
      guardian_relationship: '',
      guardian_first_name: '',
      guardian_phone: '',
      city_id: '',
    });
    this.patient = '';
    this.submitted = false;
    this.isDisable = false;
    this.resetPatientTypes();
  }

  resetPatientTypes(): any {
    const response = this.patient_types.filter((t) => t.is_default === true)[0];
    this.private_patient_type_id = response.id;

    this.companies = response.companies;
    let companiesResponse;
    if (this.companies.filter((t) => t.is_default === true)[0]) {
      companiesResponse = this.companies.filter(
        (t) => t.is_default === true,
      )[0];
    } else {
      companiesResponse = this.companies[0];
    }
    this.privete_company_id = companiesResponse.id;

    this.groups = companiesResponse.groups;
    if (this.groups.filter((t) => t.is_default === true)) {
      this.private_group_id = this.groups.filter(
        (t) => t.is_default === true,
      )[0].id;
    } else if (this.groups.length > 0) {
      this.private_group_id = this.groups[0].id;
    }
    let city = '';
    if (this.defaultLocations.city_id) {
      city = this.cityList.find(
        (t) => t.id === Number(this.defaultLocations.city_id),
      ).id;
    } else if (this.cityList.find((t) => t.is_default === true)) {
      city = this.cityList.find((t) => t.is_default === true).id;
    } else {
      city = this.cityList[0].id;
    }
    this.PatientRegistrationForm.patchValue({ city_id: city });
    let country;
    if (this.defaultLocations.country_id) {
      country = this.countriesList.find(
        (t) => t.id === Number(this.defaultLocations.country_id),
      );
    } else if (this.countriesList.find((t) => t.is_default === true)) {
      country = this.countriesList.find((t) => t.is_default === true);
    } else {
      country = this.countriesList[0];
    }
    this.PatientRegistrationForm.patchValue({
      passport_country_id: country?.id,
    });
    this.PatientRegistrationForm.patchValue({ country_id: country?.id });
      this.PatientRegistrationForm.patchValue({
        patient_type_id: this.private_patient_type_id,
        location_id: this.defaultLocations.location_id,
        state_id: this.defaultLocations?.state_id,
        company_id: this.privete_company_id,
        group_id: this.private_group_id,
      });
  }


  fetchPatientInfo(patient, ind, prePatientVerify): any {
    this.resetPatientRegistrationForm();
    setTimeout((e) => {
      $('.country').selectpicker('refresh');
      $('.country').selectpicker();
      $('.state').selectpicker('refresh');
      $('.state').selectpicker();
      $('.city').selectpicker('refresh');
      $('.city').selectpicker();
      $('.location').selectpicker('refresh');
      $('.location').selectpicker();
    }, 200);
    this.isPatientDeceased = patient.is_deceased;
    this.currentPatientInd = ind;
    // if (this.backFromService === false ) {
    if (this.patient && prePatientVerify) {
      if (patient.id === this.patient.id) {
        return;
      }
    }
    if (prePatientVerify === true) {
      if (!this.patient_types.find((m) => m.id === patient.patient_type_id)) {
        toastr.error('You dont have permission to access this patient');
        return;
      }
    }
    this.billingTab = false;
    this.zakatEligibility = false;
    this.familyTab = false;
    this.documentsTab = false;
    this.appointmentsTab = false;
    // }
    this.PatientRegistrationForm.controls.guardian_relationship.setValue('');
    this.patient = JSON.parse(JSON.stringify(patient));
    this.PatientRegistrationForm.patchValue({
      title: this.patient.title,
      first_name: this.patient.first_name,
      last_name: this.patient.last_name,
      father_name: this.patient.father_name,
      gender: this.patient.gender,
      pat_age_type: this.patient.pat_age_type,
      pat_age_value: this.patient.pat_age_value,
      patient_nic: this.patient.patient_nic,
      address1: this.patient.address1,
      phone1: this.patient.phone1,
      guardian_relationship: this.patient.guardian_relationship,
      city_id: this.patient.city_id,
      is_zakat_eligible: this.patient.is_zakat_eligible,
      guardian_nic: this.patient.guardian_nic,
      guardian_first_name: this.patient.guardian_first_name,
      guardian_phone: this.patient.guardian_phone,
      patient_type_id: this.patient.patient_type?.id || this.patient?.patient_type_id,
    });
    $('#nic').mask('99999-9999999-9').trigger('input');
    $('#guardian_nic').mask('99999-9999999-9').trigger('input');
    this.setPatientType();
    setTimeout(() => {
      this.chRef.detectChanges();
    }, 500);
    this.submitted = false;
    this.closePatientList();
  }
  checkPatientType(): any {
    this.PatientSearchForm.get('search_query').enable();
    if (this.PatientSearchForm.value.type === 'thumb') {
      $('#patient_search_here').unmask('99-##');
      this.PatientSearchForm.get('search_query').disable();
    } else if (this.PatientSearchForm.value.type === 'patient_nic') {
      $('#patient_search_here').unmask('99-##');
      $('#patient_search_here').mask('99999-9999999-9');
    } else if (this.PatientSearchForm.value.type === 'mrn') {
      $('#patient_search_here').unmask('99999-9999999-9');
      $('#patient_search_here').mask('99-##');
    } else {
      $('#patient_search_here').unmask('99999-9999999-9');
      $('#patient_search_here').unmask('99-##');
    }
  }
  setPatientType(): any {
    this.companies = [];
    this.groups = [];
    const response = this.patient_types.filter(
      (t) => t.id === this.patient.patient_type_id,
    )[0];
    this.companies = response.companies;
    const companiesResponse = this.companies.filter(
      (t) => t.id === this.patient.company_id,
    )[0];
    this.groups = companiesResponse.groups;

    this.private_patient_type_id = this.patient.patient_type_id;
    this.privete_company_id = this.patient.company_id;
    this.private_group_id = this.patient.group_id;

    this.PatientRegistrationForm.patchValue({
      patient_type_id: this.private_patient_type_id,
      company_id: this.privete_company_id,
      group_id: this.private_group_id,
    });

  }

  onChangetype(value): any {
    this.companies = [];
    this.groups = [];
    this.privete_company_id = '';
    this.private_group_id = '';

    const response = this.patient_types.filter(
      (t) => t.id === Number(value) || t.id === Number(value),
    )[0];
    this.companies = response.companies;
    let companiesResponse;
    if (this.companies.filter((t) => t.is_default === true)[0]) {
      companiesResponse = this.companies.filter(
        (t) => t.is_default === true,
      )[0];
    } else {
      companiesResponse = this.companies[0];
    }
    this.privete_company_id = companiesResponse.id;

    this.groups = companiesResponse.groups;
    if (this.groups.filter((t) => t.is_default === true)[0]) {
      this.private_group_id = this.groups.filter(
        (t) => t.is_default === true,
      )[0].id;
    } else if (this.groups.length > 0) {
      this.private_group_id = this.groups[0].id;
    }
    this.PatientRegistrationForm.patchValue({
      company_id: this.privete_company_id,
      group_id: this.private_group_id,
    });
  }

  addServices(patient): any {
    // $('#patientAddSuccessfully').modal('hide');
    this.modalService.dismissAll(this.newPatientModal);
    this.ngxLoader.start();
    const url_string = decodeURIComponent(
      '/patient/services-new?patient_id=' + patient.mrn,
    );
    this.router.navigateByUrl(url_string);
  }

  fetchPatients(): any {
    if (this.PatientSearchForm.value.type === 'thumb') {
      this.verifyThumbImpression();
      return;
    }
    this.patients = [];
    this.ngxLoader.start();
    this.PatientSearchForm.get('search_query')?.setValue(
      this.PatientSearchForm.get('search_query')?.value.replace(/-/g, ''),
    );
    let referal = false;
    if (this.current_url === '/front-desk') {
      referal = true;
    }
    this.patientService
      .searchPatientRegistrationV2(this.PatientSearchForm.value, referal)
      .subscribe(
        (data) => {
          this.patients = data.patients;
          if (this.PatientSearchForm.value.type === 'patient_nic') {
            $('#patient_search_here').mask('99999-9999999-9').trigger('input');
          } else if (this.PatientSearchForm.value.type === 'mrn') {
            $('#patient_search_here').mask('99-##').trigger('input');
          } else {
            $('#patient_search_here')
              .unmask('99999-9999999-9')
              .trigger('input');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  newRegistration(): any {
    this.isDisable = true;
    if (this.PatientRegistrationForm.get('patient_nic')?.value) {
      this.PatientRegistrationForm.get('patient_nic')?.setValue(
        this.PatientRegistrationForm.get('patient_nic')?.value.replace(
          /-/g,
          '',
        ),
      );
    }
    if (this.PatientRegistrationForm.get('guardian_nic')?.value) {
      this.PatientRegistrationForm.get('guardian_nic')?.setValue(
        this.PatientRegistrationForm.get('guardian_nic')?.value.replace(
          /-/g,
          '',
        ),
      );
    }
    this.submitted = true;
    if (this.PatientRegistrationForm.invalid) {
      this.isDisable = false;
      return;
    }
    const first: any = this.PatientRegistrationForm.get('first_name').value;
    const last: any = this.PatientRegistrationForm.get('last_name').value;
    let resultFirst = '';
    let resultLast = '';
    resultFirst = this.titleCasePipe.transform(first);
    resultLast = this.titleCasePipe.transform(last);

    this.PatientRegistrationForm.get('first_name').setValue(resultFirst);
    this.PatientRegistrationForm.get('last_name').setValue(resultLast);
    this.PatientRegistrationForm.get('state_id').setValue(this.defaultLocations.state_id);
    this.PatientRegistrationForm.get('location_id').setValue(this.defaultLocations.location_id);
    if (this.PatientRegistrationForm.value.pat_age_type !== '') {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
        ' ' +
        this.PatientRegistrationForm.value.pat_age_type,
      );
    } else {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
        ' ' +
        this.selected_age_type,
      );
    }
    if (this.PatientRegistrationForm.value.pat_age_value && this.PatientRegistrationForm.value.pat_age_type) {
      this.dob = moment().subtract(this.PatientRegistrationForm.value.pat_age_value, this.PatientRegistrationForm.value.pat_age_type).format('MM-DD-YYYY');
    } else {
      this.dob = '';
    }
    if (this.dob !== ''){
      this.PatientRegistrationForm.get('birth_month').setValue(
        this.dob.split('-')[0],
      );
      this.PatientRegistrationForm.get('birth_day').setValue(
        this.dob.split('-')[1],
      );
      this.PatientRegistrationForm.get('birth_year').setValue(
        this.dob.split('-')[2],
      );
    }
    this.ngxLoader.start();
    this.patientService.createV2(this.PatientRegistrationForm.value).subscribe(
      (data) => {
        this.patient = data.patient;
        if (this.patientImage) {
          this.uploadProfileImage('add');
        } else {
          this.patientAddGenerateComplete();
        }
      },
      (err) => {
        this.isDisable = false;
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  onChange(): any {
    this.PatientRegistrationForm.controls.gender.setValue('');
    if (
      this.PatientRegistrationForm.value.title === 'mr' ||
      this.PatientRegistrationForm.value.title === 'bo' ||
      this.PatientRegistrationForm.value.title === 'dr'
    ) {
      this.PatientRegistrationForm.controls.gender.setValue('male');
    }
    if (
      this.PatientRegistrationForm.value.title === 'mrs' ||
      this.PatientRegistrationForm.value.title === 'miss' ||
      this.PatientRegistrationForm.value.title === 'do'
    ) {
      this.PatientRegistrationForm.controls.gender.setValue('female');
    }
  }

  patientAddGenerateComplete() {
    $('#nic').mask('99999-9999999-9').trigger('input');
    $('#guardian_nic').mask('99999-9999999-9').trigger('input');
    this.isDisable = false;
    this.patients.unshift(this.patient);
    this.ngxLoader.stop();
    // this.fetchPatients();
    if (this.patient.registration_invoice_id > 0) {
      window.open(
        this.current_environment +
        '/v4/patient_services/' +
        this.patient.registration_invoice_id +
        '/patient_services_invoice_slip.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        '_blank',
      );
      this.message =
        'Patient has been successfully registered with MRN ' + this.patient.mrn;
    } else {
      this.message =
        'Patient has been successfully registered with MRN ' +
        this.patient.mrn +
        ' but no Price configured';
    }
    // $('#patientAddSuccessfully').modal('show');
    this.modalService.open(this.newPatientModal);
  }

  uploadProfileImage(type) {
    this.patientService
      .uploadProfileImage(
        this.patientImage,
        '',
        this.patient.id,
        'patient_info',
      )
      .subscribe(
        (dataImage) => {
          this.patient.profile_image = dataImage.patient_image;
          this.patientImage = '';
          if (type === 'add') {
            this.patientAddGenerateComplete();
          } else if (type === 'update') {
            this.patientUpdateComplete();
          }
        },
        (err) => {
          this.isDisable = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updatePatient(): any {
    if (this.PatientRegistrationForm.get('patient_nic')?.value) {
      this.PatientRegistrationForm.get('patient_nic')?.setValue(
        this.PatientRegistrationForm.get('patient_nic')?.value.replace(
          /-/g,
          '',
        ),
      );
    }
    if (this.PatientRegistrationForm.get('guardian_nic')?.value) {
      this.PatientRegistrationForm.get('guardian_nic')?.setValue(
        this.PatientRegistrationForm.get('guardian_nic')?.value.replace(
          /-/g,
          '',
        ),
      );
    }
    if ($('#phone1').val()) {
      this.PatientRegistrationForm.get('phone1')?.setValue(
        $('#phone1').val().replace(/-/g, ''),
      );
    }
    this.submitted = true;
    if (this.PatientRegistrationForm.invalid) {
      return;
    }
    const first: any = this.PatientRegistrationForm.get('first_name').value;
    const last: any = this.PatientRegistrationForm.get('last_name').value;
    let resultFirst = '';
    let resultLast = '';
    resultFirst = this.titleCasePipe.transform(first);
    resultLast = this.titleCasePipe.transform(last);

    this.PatientRegistrationForm.get('first_name').setValue(resultFirst);
    this.PatientRegistrationForm.get('last_name').setValue(resultLast);
    if (this.PatientRegistrationForm.value.pat_age_type !== '') {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
        ' ' +
        this.PatientRegistrationForm.value.pat_age_type,
      );
    } else {
      this.PatientRegistrationForm.get('age').setValue(
        this.PatientRegistrationForm.value.pat_age_value +
        ' ' +
        this.selected_age_type,
      );
    }
    if (this.PatientRegistrationForm.value.pat_age_value && this.PatientRegistrationForm.value.pat_age_type) {
      this.dob = moment().subtract(this.PatientRegistrationForm.value.pat_age_value, this.PatientRegistrationForm.value.pat_age_type).format('MM-DD-YYYY');
    } else {
      this.dob = '';
    }
    if (this.dob !== ''){
      this.PatientRegistrationForm.get('birth_month').setValue(
        this.dob.split('-')[0],
      );
      this.PatientRegistrationForm.get('birth_day').setValue(
        this.dob.split('-')[1],
      );
      this.PatientRegistrationForm.get('birth_year').setValue(
        this.dob.split('-')[2],
      );
    }
    this.ngxLoader.start();
    this.patientService
      .updateV2(this.PatientRegistrationForm.value, this.patient.id)
      .subscribe(
        (data) => {
          this.patient = data.patient;
          if (this.patientImage) {
            this.uploadProfileImage('update');
          } else {
            this.patientUpdateComplete();
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  patientUpdateComplete() {
    $('#nic').mask('99999-9999999-9').trigger('input');
    $('#guardian_nic').mask('99999-9999999-9').trigger('input');
    if (this.patients[this.currentPatientInd]?.id === this.patient?.id) {
      this.patients[this.currentPatientInd] = this.patient;
    }
    this.ngxLoader.stop();
    toastr.success('Successfully Updated');
  }

  get f(): any {
    return this.PatientRegistrationForm.controls;
  }
  get j(): any {
    return this.PatientSearchForm.controls;
  }

  // For Web Cam

  cambox(): any {
    // this boolean is not in this method before
    this.showImage = false;
    // this servoce open line inside the next method before
    this.modalService.open(this.imageModal);

    navigator.mediaDevices.getUserMedia({ video: true }).then(
      (stream) => {
        WebcamUtil.getAvailableVideoInputs().then(
          (mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable =
              mediaDevices && mediaDevices.length > 1;
          },
        );
        this.showImage = false;
      },
      (e) => {
        toastr.error('Allow camera permission');
        // microphone not available
      },
    );
  }
  triggerSnapshot(): void {
    this.trigger.next();
    this.showImage = true;
  }

  handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
    } else {
    }
    this.errors.push(error);
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }
  cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  resetImageBox(): any {
    this.showImage = false;
  }
  captureImageComplete(): any {
    this.patientImage = this.webcamImage.imageAsDataUrl;
    if (this.patient) {
      this.patient.profile_image = '';
    }
    this.modalService.dismissAll(this.imageModal);
  }
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  // side bar patient list
  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }

  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }

  toggleTabs(type): any {
    if (type === 'zakat') {
      this.zakatEligibility = true;
    }
  }
  billingCall(): any {
    this.billingTab = true;
  }
  familyCall(): any {
    this.familyTab = true;
  }
  documentsCall(): any {
    this.documentsTab = true;
  }
  appointmentsCall(): any {
    this.appointmentsTab = true;
  }

  searchPatientByPhoneNumber(): any {
    if ($('#phone1').val()) {
      this.PatientRegistrationForm.get('phone1')?.setValue(
        $('#phone1').val().replace(/-/g, ''),
      );
    }
    const obj = {
      search_query: this.PatientRegistrationForm.value.phone1,
      type: 'phone1',
    };
    this.patientService.searchPatientRegistrationV2(obj, false).subscribe(
      (data) => {
        if (data.patients.length > 0) {
          $('#patientFoundAgainstNumberModal').modal('show');
          this.patientAgainstPhoneNo = data.patients;
          setTimeout(() => {
            document.getElementById('patientFoundAgainstNumberBtn').focus();
          }, 500);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  patientFoundAgainstNumber(): any {
    $('#patientFoundAgainstNumberModal').modal('hide');
    this.patients = this.patientAgainstPhoneNo;
  }
  getThumbImpression() {
    if (!this.patient) {
      toastr.error('Select patient');
      return;
    }
    let patientData = {
      is_attendance: 0,
      patient_id: this.patient.id,
      redirect_url: '',
      api_url:
        this.current_environment_python +
        '/api/upload_patient_thumb_expression/',
      data_url: '',
    };
    let jsonData = JSON.stringify(patientData);
    let utf8Bytes = new TextEncoder().encode(jsonData);
    let base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));
    window.open('digitalpersona:' + base64String, '_blank');
  }
  verifyThumbImpression(): any {
    let patientData = {
      is_attendance: 0,
      patient_id: '',
      redirect_url:
        window.location.origin + '/patient/registration-v2?patient_mrn=',
      api_url: '',
      data_url:
        this.current_environment_python +
        '/api/get_patient_for_fingerprint_verification?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      background_image:
        this.current_environment_python +
        '/hr/get_background_image?medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
    };
    let jsonData = JSON.stringify(patientData);
    let utf8Bytes = new TextEncoder().encode(jsonData);
    let base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));
    window.open('digitalpersona:' + base64String, '_blank');
  }

  checkAge(event: any): any {
    const value = event.target.value;
    if (value > 150) {
      this.PatientRegistrationForm.get('pat_age_value').patchValue(150);
      event.preventDefault();
    }
  }
}
