import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskManagementService } from '../../../services/task-management.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RouteShareService } from '../../../services/route-share.service';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
})
export class ProjectComponent implements OnInit {
  project: FormGroup;
  subType: FormGroup;
  typeList: any;
  subTypeId: any;
  subTypeList: any;
  showUpdateType = false;
  isAddType = false;
  isAddSubType = false;
  showUpdateSubType = false;
  typeId: '';
  screenName = '';
  constructor(
    private fb: FormBuilder,
    private taksServices: TaskManagementService,
    private ngxLoader: NgxUiLoaderService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  ngOnInit(): void {
    $('#addTypeModal').on('hide.bs.modal', () => {
      this.project.reset({
        name: '',
        is_active: false,
      });
    });
    this.project = this.fb.group({
      name: ['', Validators.required],
      is_active: false,
    });
    this.getProjectList();
  }
  addTags(): any {
    this.showUpdateType = false;
    $('#addTypeModal').modal('show');
  }
  addSubTypes(): any {
    this.showUpdateSubType = false;
    $('#addSubTypeModal').modal('show');
  }
  getTagUpdate(id, index, data): any {
    this.typeId = id;
    this.showUpdateType = true;
    this.ngxLoader.start();
    this.project.patchValue({
      name: data.name,
      is_active: data.is_active,
    });

    this.ngxLoader.stop();
    $('#addTypeModal').modal('show');
  }

  submitType(): any {
    this.isAddType = true;
    if (this.project.invalid) {
      return;
    }

    if (this.showUpdateType === false) {
      this.taksServices.addProject(this.project.value).subscribe(
        (data) => {
          this.isAddType = false;
          $('#addTypeModal').modal('hide');
          this.project.reset({
            name: '',
            is_active: false,
          });
          this.ngxLoader.stop();
          this.getProjectList();
        },
        (err) => {
          console.log('err', err);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
      $('#addTypeModal').modal('hide');
    } else {
      this.taksServices
        .updateProject(this.project.value, this.typeId)
        .subscribe(
          (data) => {
            this.isAddType = false;
            console.log(this.typeId);
            $('#addTypeModal').modal('hide');
            this.project.reset({
              name: '',
              is_active: false,
            });
            this.showUpdateType = false;
            this.getProjectList();
            this.ngxLoader.stop();
          },
          (err) => {
            console.log('err', err);
            this.ngxLoader.stop();
            toastr.error(err.error.error);
          },
        );
    }
    this.getProjectList();
  }

  getProjectList(): any {
    this.taksServices.getProject().subscribe(
      (data) => {
        this.typeList = data;
        console.log(this.typeList);
      },
      (err) => {
        console.log('err', err);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
