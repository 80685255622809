import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientServiceInvoicesService } from '../../services/patient-service-invoices.service';
import { EmployeeRegistrationService } from '../../services/employee-registration.service';
import { AdmissionHistoryComponent } from './admission-history/admission-history.component';
import { ServiceCancelComponent } from './service-cancel/service-cancel.component';
import { AuthorizationGuard } from '../../services/authorization.guard';
import { DischargeCertificateComponent } from './discharge-certificate/discharge-certificate.component';
import { RegistrationV2Component } from './registration-v2/registration-v2.component';
import { PrintIssueItemComponent } from './print-issue-item/print-issue-item.component';
import { ServicesNewComponent } from './services-new/services-new.component';
import { ConfigurationService } from '../../services/configuration.service';
import { EmployeeVisitService } from '../../services/employee-visit.service';
import { ConfiguratorsService } from '../../services/configurators.service';
import { LanguageService } from '../../services/language.service';
import { PatientDiscountV2Component } from './patient-discount-v2/patient-discount-v2.component';
import { AdmissionV2Component } from './admission-v2/admission-v2.component';
import { PatientDiscountApprovalV2Component } from './patient-discount-approval-v2/patient-discount-approval-v2.component';
import { PatientMedicineDetailComponent } from './patient-medicine-detail/patient-medicine-detail.component';
import { IssueItemV2Component } from './issue-item-v2/issue-item-v2.component';
import { PatientDiscountV3Component } from './patient-discount-v3/patient-discount-v3.component';
import { WorkerComponent } from './worker/worker.component';
import { ServicesV2Component } from './services-v2/services-v2.component';
import { PatientMedicineDetailV2Component } from './patient-medicine-detail-v2/patient-medicine-detail-v2.component';
import { HealthCardComponent } from './health-card/health-card.component';
import { ThumbImpressionComponent } from './thumb-impression/thumb-impression.component';
import { PrintIssueItemV2Component } from './print-issue-item-v2/print-issue-item-v2.component';
import { SystemGeneralControls } from '../../services/Resolver/prescriptionDataResolver';
import {RegistrationV3Component} from './registration-v3/registration-v3.component';

const routes: Routes = [
  {
    path: 'registration-v2',
    component: RegistrationV2Component,
    resolve: { resolvedData: SystemGeneralControls },
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'registration-v3',
    component: RegistrationV3Component,
    resolve: { resolvedData: SystemGeneralControls },
  },
  {
    path: 'services-new',
    component: ServicesNewComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'admission-v2',
    component: AdmissionV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'adjustments-v2',
    component: PatientDiscountV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'adjustments-v3',
    component: PatientDiscountV3Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'adjustments-approval-v2',
    component: PatientDiscountApprovalV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'admission-history',
    component: AdmissionHistoryComponent,
    canActivate: [AuthorizationGuard],
  },
  // { path: 'adjustments-new', component: ServiceCancelComponent },
  { path: 'discharge-certificate', component: DischargeCertificateComponent },
  {
    path: 'issue-item-v2',
    component: IssueItemV2Component,
    canActivate: [AuthorizationGuard],
  },
  { path: 'detail', component: PatientMedicineDetailComponent },
  { path: 'detail-v2', component: PatientMedicineDetailV2Component },
  { path: 'issue-item-print/:id/:mrn', component: PrintIssueItemComponent },
  { path: 'issue-item-print-v2', component: PrintIssueItemV2Component },
  { path: 'worker', component: WorkerComponent },
  { path: 'services-v2', component: ServicesV2Component },
  { path: 'health-card', component: HealthCardComponent },
  { path: 'thumb-impression/:id', component: ThumbImpressionComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    PatientServiceInvoicesService,
    EmployeeRegistrationService,
    ConfigurationService,
    EmployeeVisitService,
    ConfiguratorsService,
    LanguageService,
  ],
})
export class PatientRoutingModule {}
