import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class PatientService {
  constructor(private http: HttpClient) {}

  getIPDPatients(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_registration_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&admission=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAdvanceData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/detail_advance/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&advance_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientDataForHealthCard(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_front_desk_control/patient_card_detail/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&pat_mrn=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByAdjustmentApproval(patient, page_url): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query +
          '&page_url=' +
          page_url,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByAdjustmentApprovalV2(patient, page_url): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_requested_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query +
          '&page_url=' +
          page_url,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByType(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByTypeDiscountV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_adjustments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByTypeAdmissionV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientAndMedicinesData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/medicine_request_zip_locker/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByTypeSearch(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientMedicines(mrn, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/patient_medication_flow/print_all_tag/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&mrn=' +
          mrn +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByTypeSearchV2(mrn): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patient_detail_by_mrn?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&mrn=' +
          mrn,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByRegType(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_registration_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python Start
  searchPatientByRegTypeV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_registration_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End

  searchPatientRegistration(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_registration_data_optimized?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitFamilyInfo(familyInfo, patientId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/patient_family_history/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          family_info: familyInfo,
          patient_id: patientId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateFamilyInfo(familyInfo, id, patientId): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/patient_family_history/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          family_info: familyInfo,
          patient_id: patientId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getFamilyInfoData(patientId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_family_history/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientRegistrationV2(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_registration_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientRegistrationV2ForWorker(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_workers_registration_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientRegistrationV2ForWorkerList(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/worker/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByTypeRegSearch(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_registration_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByDeathType(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/death_certificates/patients?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&request_type=' +
          patient.request_type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByDeathTypeV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/employee/death_certificate/patients/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&request_type=' +
          patient.request_type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDeceasedDischargeDetails(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/death_certificates/death_certificate?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDeceasedDischargeDetailsV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/employee/death_certificate/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deceasedCertificateIssue(
    dischargePatient,
    patient,
    deathCertificateId,
    type,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/death_certificates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.id,
          death_certificate_id: deathCertificateId,
          death_certificate: dischargePatient,
          request_status: type,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  deceasedCertificateIssueV2(
    dischargePatient,
    patient,
    deathCertificateId,
    type,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/employee/death_certificate/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.id,
          death_certificate_id: deathCertificateId,
          death_certificate: dischargePatient,
          request_status: type,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deceasedCertificateIssueList(
    currentPage: any,
    perPage: any,
    filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/death_certificates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          filters.from_date +
          '&to_date=' +
          filters.to_date +
          '&patient_mrn=' +
          filters.patient_id +
          '&request_type=' +
          filters.type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByTypeSearchFromEmergency(patient, referred): Observable<any> {
    // get_emergency_patients
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_emergency_patients_optimized?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query +
          '&emr=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientsByTypeSearchFromOPD(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_opd_patients_optimized?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query +
          '&opd=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientsByTypeSearchFromOPDV2(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/visits/get_opd_patients_by_visit?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query +
          '&opd=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientsByTypeOPDV2(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/get_opd_patients_by_visit/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query +
          '&opd=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientsByTypeEmergencyV2(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/emergency/get_emergency_patients_optimized/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query +
          '&emr=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchIpdPatient(patient, ward, doctor_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type_optimized?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&ward_id=' +
          ward +
          '&ward=true&admission=true&query=' +
          patient.search_query +
          '&doctor_id=' +
          doctor_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchIpdPatientV2(patient, ward, doctor_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_optimized/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&ward_id=' +
          ward +
          '&ward=true&admission=true&query=' +
          patient.search_query +
          '&doctor_id=' +
          doctor_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientsVitalsWeight(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_optimized/send_vital/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByTypeWard(patient, ward, doctor_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&ward_id=' +
          ward +
          '&ward=false&admission=false&query=' +
          patient.search_query +
          '&doctor_id=' +
          doctor_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientByType(patient, ward, doctor_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&ward_id=' +
          ward +
          '&ward=&admission=&query=' +
          patient.search_query +
          '&doctor_id=' +
          doctor_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientByTypeV2(patient, ward, doctor_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&ward_id=' +
          ward +
          '&ward=&admission=&query=' +
          patient.search_query +
          '&doctor_id=' +
          doctor_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchAdmittedPatient(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&admission=true&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchRadiologyServices(subDepart): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/rad_emp_summarized_data/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&sub_dept_id=' +
        subDepart,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchAdmittedPatientV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_admissions/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&admission=true&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchRequestedPatient(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/reservations/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&reservation=true&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchRequestedPatientV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_admissions/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&reservation=true&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchFinalSettlementPatient(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&admission=true&final_settlement=true&query=' +
          patient.search_query +
          '&is_hold=' +
          patient.is_hold,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchFinalSettlementPatientV2(patient, type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_fs/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&admission=true&final_settlement=true&query=' +
          patient.search_query +
          '&is_hold=' +
          patient.is_hold +
          '&result_type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchFinalSettlementPatientFromIssueItems(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&admission=true&final_settlement=true&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchFinalSettlementPatientFromIssueItemsV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type_optimized?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&admission=true&final_settlement=true&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  create(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&location_id=' +
          patient.location_id,
        {
          patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // sendPatientId(patient_id): Observable<any> {
  //   const results = this.http.post(`${environment.rorUrl}/v4/patients/bar_and_qr_code_generate_v2?auth_token=` + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'), {
  //     patient_id,
  //   }, {headers: new HttpHeaders({
  //       AuthenticationToken: localStorage.getItem('auth_token'),
  //       medical_unit_id : localStorage.getItem('current_medical_unit_id'),
  //       Accept: 'application/json'
  //     })}).pipe(map(data => data));
  //   return results;
  // }

  createV2(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/patients/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&location_id=' +
          patient.location_id,
        {
          patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  uploadProfileImage(patient_image, family_image, id, type): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/image_upload/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_image,
          id,
          family_image,
          type: type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createV2ForWorker(patient, patient_image): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/worker/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&location_id=' +
          patient.location_id,
        {
          patient,
          patient_image,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  update(patient, id: number): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&location_id=' +
          patient.location_id,
        {
          patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getWorkerData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_worker/` +
          id +
          `/?medical_unit_id=152`,
        {
          headers: new HttpHeaders({
            AuthenticationToken: '',
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateV2(patient, id: number): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/patients/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&location_id=' +
          patient.location_id,
        {
          patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateV2ForWorker(patient, id: number, patient_image): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/worker/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&location_id=' +
          patient.location_id,
        {
          patient,
          patient_image,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPatientService(patient_service, patient, status): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          patient_service: patient_service,
          patient_id: patient.id,
          payment_status: status,
          nursing: false,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python Start
  createPatientServiceV2(
    patient_service,
    patient,
    status,
    anyContraindicationsToReceiveContrast,
    anyAllergiesToIodinatedContrast,
    anyContraindicationsToReceiveContrastTextarea,
    userInfo,
    currentPage,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/patient_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&current_page=' +
          currentPage,
        {
          patient_service: patient_service,
          patient_id: patient.id,
          payment_status: status,
          nursing: false,
          any_contraindications_To_receive_contrast:
            anyContraindicationsToReceiveContrast,
          any_allergies_to_iodinated_contrast: anyAllergiesToIodinatedContrast,
          any_contraindications_to_receive_contrast_textarea:
            anyContraindicationsToReceiveContrastTextarea,
          user_info: userInfo,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End
  unpaidToPaidPatientInvoices(invoices_details, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_service_invoices/pay_unpaid_invoices?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          invoices_details,
          patient_id: patient.id,
          payment_status: 'paid',
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  unpaidToPaidPatientInvoicesV2(invoices_details, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/pay_unpaid_invoices/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          invoices_details,
          patient_id: patient.id,
          payment_status: 'paid',
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientServices(patient, history, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&history=' +
          history +
          '&visit_id=' +
          patient.visit_id +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientServicesTabV2(
    patient,
    history,
    visitType,
    page,
    perPage,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&history=' +
          history +
          '&visit_id=' +
          patient.visit_id +
          '&visit_type=' +
          visitType +
          '&page=' +
          page +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLisIntegration(external_lis_base_url, LabOrderId): Observable<any> {
    const results = this.http
      .get(external_lis_base_url + `/hmisinteg/api/patientdata/` + LabOrderId, {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
    return results;
  }
  patientServicesV2(patient, history): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&history=' +
          history +
          '&visit_id=' +
          patient.visit_id +
          '&service_id=' +
          patient.service_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientServicesInvoiceByMrn(patient, visit, paymentType): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_service_invoices?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type=' +
          visit +
          '&payment_status=' +
          paymentType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientServicesInvoiceByMrnV2(patient, visit, paymentType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_service_invoice/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type=' +
          visit +
          '&payment_status=' +
          paymentType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientServicesInvoice(patient, currentPage, perPage): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_service_invoices?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&final_settlement=true' +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientServicesInvoiceV2(patient, currentPage, perPage): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_service_invoice_fs/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&final_settlement=true' +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientStats(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/final_settlements/get_ipd_invoice_data?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientStatsV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_ipd_invoice_data/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPatientServiceForEmr(
    patient,
    patient_service,
    payment_status,
  ): Observable<any> {
    patient_service.patient_id = patient.id;
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service.patient_id,
        {
          patient_service,
          payment_status,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPatientService(
    patient,
    patient_service,
    payment_status,
    requested,
  ): Observable<any> {
    patient_service.patient_id = patient.id;
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service.patient_id +
          '&opd_e_pres=' +
          requested,
        {
          patient_service,
          payment_status,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPatientServiceIPD(
    patient,
    patient_service,
    payment_status,
  ): Observable<any> {
    patient_service.patient_id = patient.id;
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service.patient_id +
          '&e_pres=true',
        {
          patient_service,
          payment_status,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPatientServiceIPDV2(
    patient,
    patient_service,
    payment_status,
    opd,
    isReq,
  ): Observable<any> {
    patient_service.patient_id = patient.id;
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/patient_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_service.patient_id +
          '&e_pres=' +
          isReq +
          '&is_opd=' +
          opd,
        {
          patient_service,
          payment_status,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeServiceType(id, value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/add_hospital_consultant_check/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  finalSettlementPatient(patient, settlement, claimable_bill): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/final_settlements?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&claimable_bill=' +
          claimable_bill,
        {
          final_settlement: settlement,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  finalSettlementPatientV2(
    patient,
    settlement,
    claimable_bill,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/final_settlements_create/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&claimable_bill=' +
          claimable_bill,
        {
          final_settlement: settlement,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createOtMedicineInvoice(admissionId): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/api/admission_create/create_ot_medicines_invoice/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          admission_id: admissionId,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  createDirectVoucher(admissionId, calObj, bookOfAccount, batch): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/direct_voucher/`,
        {
          batch: batch,
          book_of_account: bookOfAccount,
          admission_id: admissionId,
          values: calObj,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  createAdvanceVoucher(advanceId, bookOfAccount, batch): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/direct_advance_voucher/`,
        {
          advance: advanceId,
          book_of_account: bookOfAccount,
          batch,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  createAdvanceCancelVoucher(advanceIds, bookOfAccount, batch): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/finance/direct_advance_return_voucher/`,
        {
          advance: advanceIds,
          book_of_account: bookOfAccount,
          batch,
        },
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  addPatientAdvance(patient, settlement): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` + patient.mrn + '/advances',
        {
          advance: settlement,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPatientAdvanceV2(patient, settlement): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/advance/` +
          patient.mrn +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          advance: settlement,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientServicesHistoryDates(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_services/group_by_date?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateStayCharges(patient, vaue): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/update_calculate_stay_charges?patient_id=` +
          patient.mrn +
          '&calculate_stay_charges=' +
          vaue +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByDoctor(patient, referred): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&referred=' +
          referred +
          '&query=' +
          patient.search_query +
          '&doctor_type=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientFetchByMRN(patient_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/fetch_by_mrn?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // New Blood Bank Under developement :: after: Atta : by UMAR
  searchPatientByTypeRegSearchNew(patient, referred, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/search_patient_blood_bank/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient +
          '&referred=' +
          referred +
          '&query=' +
          query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSubDepartmentsAgainstServiceType(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/sub_department/sub_dept_drop_down?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_type=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSubDepartmentsAgainstServiceTypeLab(): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/sub_department/sub_dept_drop_down/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_type=LabInvestigation',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchServicesName(assignable_type, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/services_by_department?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&assignable_type=' +
          assignable_type +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatient(search_string, type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/booking/client_search/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&search_string=' +
          search_string +
          '&search_type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchSalesRemarksFun(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/visit_remarks/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateSalesRemarksFun(formData): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/visit_remarks/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          value: formData,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
